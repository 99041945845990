body {
	background: black;
	color: white;
	font-family: -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
		Droid Sans, Helvetica Neue, Arial, sans-serif;
}

div#root {
	max-width: 1024px;
	margin: 0 auto;
	padding-bottom: 5rem;
}

div#root > header {
	position: sticky;
	top: 0;
	z-index: 1;
	background: black;
	padding: 0.5rem 0 1rem;
	outline: 1px solid black;
}

nav ul {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

nav li.exit {
	/* position: absolute; */
	/* left: 0; */
	/* top: 0.5rem; */
}

nav a {
	/* font-size: 1.2rem; */
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

a,
button {
	color: #87ceeb;
	text-decoration: none;
}

a:hover,
button:hover {
	color: rgba(135, 206, 235, 0.85);
}

a.selected {
	border-bottom: 1px solid;
}

label {
	padding: 0 0.1rem;
	cursor: pointer;
}

label.playbill {
	display: block;
}

form form {
	display: inline-block;
}

fieldset div {
	display: flex;
	flex-wrap: wrap;
}

fieldset legend + div {
	margin-bottom: 1rem;
}

label + ul,
label + div {
	margin-top: 1rem;
}

h2,
h3,
h4 {
	margin: 0;
}

label {
	position: relative;
	cursor: pointer;
	display: inline-block;
	padding: 0 0 0 0.5rem;
}

label:first-of-type {
	padding: 0;
}

img {
	display: block;
	margin: 0 auto;
	min-width: 100px;
	max-height: 300px;
	max-width: 200px;
	border: 1px solid #ccc;
}

label.playbill span {
	display: block;
	text-align: center;
}

input,
select,
textarea {
	background: #444;
	color: inherit;
	/* padding: 0.2rem 0.5rem; */
	font: inherit;
	/* border: 0; */
	/* border-radius: 5px; */
	/* outline: 0; */
	/* margin: 0; */
}

input[type="date"],
input[type="time"] {
	padding: 0 0 0 0.5rem;
	cursor: pointer;
	height: 26px;
}

input[type="date"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	display: none;
}

input[type="time"]::-webkit-inner-spin-button:hover {
	cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
	background: transparent;
	cursor: pointer;
}

::-webkit-input-placeholder,
::placeholder {
	color: #ccc;
}

section.details input[type="file"] {
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	width: 0;
}

button {
	font-size: 1rem;
	padding: 0.5rem 2rem;
	background: #111;
	border: 2px solid;
	cursor: pointer;
	border-radius: 3px;
	font-weight: bold;
	outline: 0;
}

button.inline {
	padding: 0.25rem 0.5rem;
	margin: 0;
	border-width: 1px;
	font-size: 0.8rem;
}

button.tool {
	height: 2rem;
	margin-left: 1rem;
	white-space: nowrap;
}

button.tool.destructive {
	color: red;
	margin-left: 2rem;
}

section > div {
	border: 0.5px solid #ccc;
	padding: 0.5rem;
	background: #444;
	resize: vertical;
	overflow: scroll;
	margin-bottom: 2rem;
	flex-basis: 100%;
	min-height: 4rem;
}

fieldset {
	margin: 1rem 0 2rem;
	border-radius: 3px;
	border: 0;
	padding: 0;
}

fieldset.simple {
	padding: 0;
	border: 0;
}

fieldset fieldset {
	margin-bottom: 0;
	padding: 0 1rem;
}

legend {
	margin-bottom: 1rem;
	border-bottom: 1px solid;
	width: 100%;
	padding-bottom: 0.25rem;
	box-sizing: border-box;
}

fieldset.simple legend {
	position: absolute;
	opacity: 0;
	width: 0;
}

fieldset > label,
fieldset > input,
fieldset > button {
	vertical-align: middle;
}

textarea {
	background: #444;
	color: inherit;
	display: block;
	width: 100%;
	height: 200px;
	resize: vertical;
	font: inherit;
	box-sizing: border-box;
}

section > label {
	flex-basis: 100%;
	font: inherit;
}

input[type="email"] {
	margin-bottom: 0;
}

:disabled + span {
	color: #777;
}

fieldset label:last-child {
	margin-right: 0;
}

fieldset label,
fieldset > input {
	margin-right: 1rem;
}

select {
	height: 26px;
	cursor: pointer;
}

main ul a {
	flex: 1;
}

main ul a:hover {
	background: rgba(255, 255, 255, 0.1);
}

main li {
	border-top: 1px solid #666;
	display: flex;
	align-items: center;
}

main li:last-child {
	border-bottom: 1px solid #666;
}

main li div {
	padding: 1rem 0;
}

main ul h3 {
	padding: 1rem 0;
	margin: 0;
}

main ul p {
	margin: 0;
}

main ul p:last-child {
	margin-bottom: 1rem;
}

main ul div h3,
main ul div p {
	padding: 0;
}

main ul div p:last-child {
	margin: 0;
}

.details_header span {
	display: inline-block;
	height: 6rem;
	width: 4rem;
	background: no-repeat;
	background-size: cover;
	background-position: center center;
	vertical-align: middle;
	outline: 1px solid #666;
	position: relative;
}

.details_header:not(.no_person) span::before {
	content: "";
	background: url(https://beta.breaklegs.com/images/no_picture_available.png)
		no-repeat;
	background-size: cover;
	background-position: center center;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
}

.details_header div {
	margin-left: 1rem;
	display: inline-block;
	vertical-align: middle;
}

.details_header p {
	margin: 0;
}

section.member_details .details_header h3 + p {
	margin-top: 1rem;
}

select[name="locationId"] {
	max-width: 12rem;
}

section.membership h2 {
	margin: 0 0 1rem;
	border-bottom: 1px solid;
}

section.membership h2 ~ h2 {
	margin: 2rem 0 1rem;
}

section.membership li {
	display: grid;
	grid-template-areas:
		"a copy"
		"a delete";
	grid-auto-columns: 8fr 1fr;
}

section.membership ul ~ ul li {
	grid-template-areas: "a copy";
}

section.membership ul a {
	grid-area: a;
	margin-right: 1rem;
}

section.membership button.tool {
	margin: 0;
	grid-area: copy;
	align-self: center;
}

section.membership button.tool.destructive {
	grid-area: delete;
}

section.events li {
	display: grid;
	grid-template-areas:
		"a day"
		"a week"
		"a delete";
	grid-auto-columns: 8fr 1fr;
}

section.events ul a {
	grid-area: a;
	margin-right: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

section.events button.tool {
	margin-left: 0;
}

section.events button.tool:first-of-type {
	grid-area: day;
	margin: 1rem 0;
}

section.events button.tool:nth-of-type(2) {
	grid-area: week;
}

section.events button.tool.destructive {
	margin: 2rem 0 1rem;
	grid-area: delete;
}

section.fileDetails fieldset > label {
	display: block;
	margin-bottom: 1rem;
}

section.fileDetails fieldset > div {
	margin: 0;
	width: 50%;
	height: 300px;
	outline: 1px dotted grey;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	vertical-align: top;
}

section.fileDetails ol {
	display: inline-block;
	padding: 0;
	width: calc(50% - 2rem);
	list-style: decimal;
	margin: 0 0 0 2rem;
}

section.fileDetails li {
	display: list-item;
	border: 0 !important;
	margin-bottom: 0.5rem;
}
